import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { App, ConfigProvider } from "antd";
import { Provider } from "react-redux";

import Home from "./Home";
// import { Login } from "./Auth";
// import Dashboard from "./Dashboard/Dashboard";

// Store
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#00a8e8",
                        colorWarning: "#ffd166",
                        colorSuccess: "#06d6a0",
                        colorError: "#ef476f",
                        borderRadius: 15,
                        borderRadiusOuter: 15,
                    },
                    components: {
                        Menu: {
                            iconSize: 20,
                            fontSize: 16,
                        },
                    },
                }}
            >
                <App>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            {/* <Route path="/login" element={<Login />} />
                            <Route path="/dashboard" element={<Dashboard />}>
                                <Route path="" element={<Login />} />
                                <Route path="vault" element={<Login />} />
                                <Route path="notes" element={<Login />} />
                                <Route path="ssh" element={<Login />} />
                            </Route> */}
                        </Routes>
                    </BrowserRouter>
                </App>
            </ConfigProvider>
        </Provider>
    </React.StrictMode>
);
