import axios from "axios";

import { dispatchError, dispatchLoading, url } from "../utils";

export const SubscribeNewsLetter = (data, message) => async (dispatch) => {
    dispatchLoading(dispatch, "newsletter", true);
    axios
        .post(`${url}/newsletter`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "newsletter");
            if (result?.data?.success) {
                message.success("You have been Subscribed");
            } else if (result?.data?.data?.code === 1) {
                message.success("You are already Subscribed");
            }
        })
        .catch((err) => {
            if (err?.response?.status === 400) dispatchError(dispatch, "newsletter", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "newsletter", false));
};
