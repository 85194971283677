import { Card, Divider } from "antd";
import { Footer, Header, SubscribeCard } from "./Components";
import { SafetyOutlined, FileProtectOutlined, CloudServerOutlined } from "@ant-design/icons";

const Home = () => {
    return (
        <div className="container">
            <Header />

            <Divider className="my-5" />
            <Divider />
            <Section1 />

            <Divider />
            <Section2 />

            <SubscribeCard />
            <Footer />
        </div>
    );
};

const Section1 = () => {
    return (
        <div className="row py-5">
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                <h1 className="kaushan text-center my-3 fw-bold">
                    What We <br />
                    Provide Here
                </h1>
            </div>
            <div className="col-12 col-md-6">
                <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                        <Card className="h-100">
                            <span className="text-center d-flex align-items-center">
                                <h1 className="me-2">
                                    <SafetyOutlined />
                                </h1>
                                <h5>Password Manager</h5>
                            </span>
                        </Card>
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <Card className="h-100">
                            <span className="text-center d-flex align-items-center">
                                <h1 className="me-2">
                                    <CloudServerOutlined />
                                </h1>
                                <h5>SSH Vault</h5>
                            </span>
                        </Card>
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <Card className="h-100">
                            <span className="text-center d-flex align-items-center">
                                <h1 className="me-2">
                                    <FileProtectOutlined />
                                </h1>
                                <h5>Secure Notes</h5>
                            </span>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Section2 = () => {
    return (
        <div className="row py-5">
            <div className="col-12 col-lg-4 px-5 py-3">
                <h1 className="kaushan mb-2 fw-bold">
                    Who We
                    <br />
                    Really Are?
                </h1>
                <p style={{ textAlign: "justify" }}>We are a team dedicated to enhancing security while maintaining user-friendliness. Merging innovative development with expert support, we deliver a secure and intuitive experience for our users.</p>
            </div>
            <div className="col-12 col-lg-4 px-5 py-3">
                <h1 className="kaushan mb-2 fw-bold">
                    How Secure
                    <br />
                    Is Your Data?
                </h1>
                <p style={{ textAlign: "justify" }}>
                    We utilize the state-of-the-art <b>AES-CBC-256</b> encryption algorithm to secure all your data, ensuring no detail is overlooked. Only you have access to your information, guaranteeing its confidentiality.
                </p>
            </div>
            <div className="col-12 col-lg-4 px-5 py-3">
                <h1 className="kaushan mb-2 fw-bold">
                    Inspiration Born
                    <br />
                    from Experience!
                </h1>
                <p style={{ textAlign: "justify" }}>After experiencing password leak, I realized how vulnerable our digital lives are. I created this app to empower users to take control of their online security and enhance their digital safety.</p>
            </div>
        </div>
    );
};

export default Home;
