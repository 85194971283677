import { configureStore } from "@reduxjs/toolkit";

/** Reducers */
const loadingReducer = (
    state = {
        userLoading: false,
        dashboardLoading: false,
    },
    action
) => {
    switch (action.type) {
        case "SET_LOADING":
            return Object.assign({}, state, {
                [`${action.payload.scope}Loading`]: action.payload.value,
            });
        default:
            return state;
    }
};

const errorReducer = (state = {}, action) => {
    switch (action.type) {
        case "SET_ERROR":
            if (!action.payload?.value?.length) return { ...state, [`${action.payload?.scope}Error`]: {} };
            let temp = {};
            action.payload?.value?.forEach((ele) => {
                temp[`${ele?.path}`] = ele?.msg;
            });
            return Object.assign({}, state, {
                [`${action.payload?.scope}Error`]: temp,
            });

        default:
            return state;
    }
};

const store = configureStore({
    reducer: {
        loading: loadingReducer,
        error: errorReducer,
    },
});

export default store;
