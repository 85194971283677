import { App, Button, Card, Input, Space } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubscribeNewsLetter } from "./Redux/newsletter";

export const Header = () => {
    return (
        <header className="my-5 py-md-5">
            <h1 className="kaushan text-center fw-bold mt-5" style={{ fontSize: "10vw" }}>
                LeoSecurity
            </h1>
            <h6 className="text-center mb-4">Easy, Convenient, and Secure.</h6>
            <p className="text-center">
                <Button type="primary" danger size="large" href="#WaitList" className="text-decoration-none px-5 fw-bold">
                    Join Waitlist
                </Button>
            </p>
        </header>
    );
};

export const Footer = () => {
    return (
        <footer className="mt-5 text-center">
            <p>&copy; {new Date().getFullYear()} LeoSecurity. All rights reserved.</p>
        </footer>
    );
};

export const SubscribeCard = () => {
    const [email, setEmail] = useState();

    const { message } = App.useApp();
    const { newsletterLoading } = useSelector((state) => state.loading);
    const { newsletterError } = useSelector((state) => state.error);

    const dispatch = useDispatch();

    const handleSubmit = () => {
        dispatch(SubscribeNewsLetter({ email, appRelease: true }, message));
        setEmail("");
    };
    return (
        <div className="px-3" id="WaitList">
            <Card className="mx-auto d-block align-items-start" style={{ maxWidth: 600 }}>
                <h1 className="kaushan">
                    Stay Informed <br />
                    About Our Launch!
                </h1>
                <h6 className="mb-3 mt-1 fw-normal">Subscribe with your email to be among the first to know when LeoSecurity is released, and receive exclusive updates and insider news directly in your inbox.</h6>

                <Space.Compact className="w-100">
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" size="large" disabled={newsletterLoading} />
                    <Button type="primary" danger size="large" loading={newsletterLoading} onClick={handleSubmit}>
                        Subscribe
                    </Button>
                </Space.Compact>
                <span className="colorError">{newsletterError?.email}</span>
            </Card>
        </div>
    );
};
